<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form ref="banktobankForm" @submit.prevent="" class="custom-form">
                    <v-card color="white shadow1 rounded mb-4">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar :elevation="0" style="border-bottom: 1px solid #ddd !important;">
                                    <v-toolbar-title class="subtitle-1">Bank To Bank Transaction Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row justify="center">
                                        <v-col cols="8">
                                            <v-row no-gutters>
                                                <v-col cols="6">
                                                    <v-row no-gutters class="mb-1 align-center">
                                                        <v-col cols="3">Txn. ID</v-col>
                                                        <v-col cols="9">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                readonly
                                                                v-model="banktobank.code"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters class="mb-1 align-center">
                                                        <v-col cols="3">Debit Account</v-col>
                                                        <v-col cols="9">
                                                            <v-combobox
                                                                dense
                                                                outlined
                                                                hide-details
                                                                :rules="[v => !!v]"
                                                                item-text="debit_account"
                                                                item-value="id"
                                                                :items="['Test Account', 'Test Account 2']"
                                                                v-model="banktobank.debit"
                                                            >
                                                            </v-combobox>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters class="mb-1 align-center">
                                                        <v-col cols="3">Credit Account</v-col>
                                                        <v-col cols="9">
                                                            <v-combobox
                                                                dense
                                                                outlined
                                                                hide-details
                                                                :rules="[v => !!v]"
                                                                item-text="credit_account"
                                                                item-value="id"
                                                                :items="['Test Account', 'Test Account 2']"
                                                                v-model="banktobank.credit"
                                                            >
                                                            </v-combobox>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-row no-gutters class="mb-1 align-center"> 
                                                        <v-col cols="3" class="text-right pr-2">Tnx. Date</v-col>
                                                        <v-col cols="9">
                                                            <v-menu
                                                                v-model="menu"
                                                                :close-on-content-click="false"
                                                                :nudge-right="0"
                                                                transition="scale-transition"
                                                                offset-y
                                                                min-width="auto"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        v-model="banktobank.date"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        autocomplete="off"
                                                                    >
                                                                        <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                                    </v-text-field>
                                                                </template>
                                                                <v-date-picker
                                                                    v-model="banktobank.date"
                                                                    @input="menu = false"
                                                                >
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters class="mb-1 align-center">
                                                        <v-col cols="3" class="text-right pr-2">Amount</v-col>
                                                        <v-col cols="9">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                :rules="[v => !!v]"
                                                                v-model.number="banktobank.amount"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters class="mb-1 align-center">
                                                        <v-col cols="3" class="text-right pr-2">Description</v-col>
                                                        <v-col cols="9">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model.trim="banktobank.note"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="3"></v-col>
                                                        <v-col cols="9">
                                                            <v-row no-gutters>
                                                                <v-col cols="6" class="pr-1">
                                                                    <v-btn type="submit" :loading="logading" dark block color="text_bg_fave">Save</v-btn>
                                                                </v-col>
                                                                <v-col cols="6">
                                                                    <v-btn @click="resetForm" dark block color="deep-orange">Clear</v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// import confirmDialog from "../../components/confirm-dialog.component";
export default {
    name: 'BankToBank',

    components: {
        // 'delete-confirm': confirmDialog,
    },

    data: ()=> ({
        
        banktobank: {
            id: null,
            txn_id: '',
            debit_account_id: null,
            credit_account_id: null,
            amount: 0,
            date: new Date().toISOString().slice(0, 10),
            note: '',
        },

        logading: false,
        deleteId: null,
        menu: false,
        userType: ''
    }),

    watch: {
       
    },

    async created() {
        

    },

    methods: {

    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: 3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
</style>